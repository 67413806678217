/*!
 *    Personal website of Sefa Eyeoglu
 *    Copyright (C) 2018-2022  Sefa Eyeoglu <contact@scrumplex.net>
 *
 *    This program is free software: you can redistribute it and/or modify
 *    it under the terms of the GNU Affero General Public License as published by
 *    the Free Software Foundation, either version 3 of the License, or
 *    (at your option) any later version.
 *
 *    This program is distributed in the hope that it will be useful,
 *    but WITHOUT ANY WARRANTY; without even the implied warranty of
 *    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *    GNU Affero General Public License for more details.
 *
 *    You should have received a copy of the GNU Affero General Public License
 *    along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import "variables";

@import "normalize";
@import "typography";
@import "grid";
@import "base";
@import "buttons";
@import "misc";
@import "animations";
@import "scrollbar";

@import "../icons/icons.css";
